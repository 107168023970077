<template>

  <v-container
    fluid
    class="ma-0 pa-0 pt-1"
    >

    <Confirm ref="confirm" />

    <!-- <div                                                                                                           -->
    <!--   class="ml-sm-4"                                                                                              -->
    <!--   v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'application'"                                 -->
    <!--   :style="$vuetify.breakpoint.mdAndUp                                                                          -->
    <!--     ? 'display: grid; grid-template-columns: 300px auto;'                                                      -->
    <!--     : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'" -->
    <!--   >                                                                                                            -->
    <div
      class="ml-sm-4"
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'application'"
      :style="$vuetify.breakpoint.mdAndUp
        ? ''
        : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'"
      >

      <div style="grid-column: 1;">

        <!-- <div                                                        -->
        <!--   :style="$vuetify.breakpoint.mdAndUp                       -->
        <!--     ? 'width: 300px; position: sticky; overflow-y: scroll;' -->
        <!--     : ''                                                    -->
        <!--     ">                                                      -->
        <div
          :style="$vuetify.breakpoint.mdAndUp
            ? 'max-width: 600px; position: sticky; overflow-y: scroll;'
            : ''
            ">

         <!--<v-list-item class="pt-0 px-2">-->

          <v-list-item class="overline pt-0 mt-0 pl-1">
            Application
            <v-spacer />

            <div class="pl-2">
            </div>

          </v-list-item>

          <v-card
            outlined
            class="pa-0 ma-0 mt-2 flex-grow-1"
            style="">

            <v-card-title class="py-2">
              <div>
                <span class="captioned" caption="First Name">{{ application.first_name }}</span>
                <span v-if="application.middle_name" class="captioned" caption="Middle Name">{{ application.middle_name }}</span>
                <span class="captioned" caption="Last Name">{{ application.last_name }}</span>
              </div>
              <v-spacer />
              <!-- <v-icon :style="{display: channel.active ? 'none' : 'inline'}" color="red">mdi-minus-circle-outline</v-icon> -->
            </v-card-title>

            <v-card-text class="mt-6">
              <div class="mb-2 float-right text-right">
                <div class="grey--text text--lighten-0">Application Id</div>
                <div>{{ application.id }}</div>
              </div>

              <div>
                <div>{{ application.street1 }}</div>
                <div>{{ application.street2 }}</div>
                <div>{{ application.settlement }}</div>
                <div>{{ countryName(application.country) }}</div>
              </div>

              <div v-if="birth_contry" class="captioned" caption="Birth Country">
                {{ countryName(application.birth_country) }}
              </div>

              <v-divider class="my-4" />

              <div>
                <div class="mb-1">{{ application.mobile_phone }}</div>
                <div class="mb-2">{{ application.email }}</div>
                <div class="mb-2">
                  <div class="grey--text">Birth Date</div>
                  <div>{{ application.birth_date }}</div>
                </div>
                <div class="mb-2">
                  <div class="grey--text">Gender</div>
                  <div>{{ application.gender }}</div>
                </div>
              </div>
              <!-- <template v-if="application.country">    -->
              <!--   {{ countryName(application.country) }} -->
              <!-- </template>                              -->

              <!-- <template v-else-if="!channel.city || !channel.country || !channel.zip"> -->
              <!--   <em>No Address</em>                                                    -->
              <!-- </template>                                                              -->

              <!-- <template v-if="channel.city && channel.country"> -->
              <!--   <br />                                          -->
              <!--   {{ channel.city }}, {{ countryName }}           -->
              <!-- </template>                                       -->

              <!-- <template v-else-if="channel.city">               -->
              <!--   <br />                                          -->
              <!--   {{ channel.city }}                              -->
              <!-- </template>                                       -->

              <!-- <template v-else-if="channel.country">            -->
              <!--   <br />                                          -->
              <!--   {{ countryName }}                               -->
              <!-- </template>                                       -->

              <!-- <template v-if="channel.zip">                     -->
              <!--   <br />                                          -->
              <!--   {{ channel.zip }}                               -->
              <!-- </template>                                       -->

              <!-- <template v-if="channel.email">                   -->
              <!--   <br />                                          -->
              <!--   {{ channel.email }}                             -->
              <!-- </template>                                       -->

              <!-- <template v-if="channel.phone">                   -->
              <!--   <br />                                          -->
              <!--   {{ formatPhone(channel.phone) }}                -->
              <!-- </template>                                       -->

            </v-card-text>

          </v-card>


        </div>
      </div>

      <div
        class="mt-sm-0 ml-sm-1"
        v-if="$vuetify.breakpoint.mdAndUp"
        style="grid-column: 2; overflow: scroll;"
        >
        <router-view />
      </div>
    </div>

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import Confirm from '@/components/Confirm.vue'
import ct from 'countries-and-timezones'

export default {
  data() {
    return {
      channelId: undefined,
      channel: {},

      deleting: false,

      networkFilter: '',
      networkSelectedIndex: undefined,
      networkSelected: 0,
      filteredNetworkList: [],

      networkId: undefined,

      newNetworkDialog: false,
    }
  },

  metaInfo() {
    return {
      title: 'Networks',
    }
  },

  computed: {
    ...mapGetters('Application', ['applicationById', 'applications']),
  },

  components: {
    Confirm,
  },

  methods: {
    ...mapActions('Application', [ 'removeApplication' ]),

    countryName(code) {
      return ct.getCountry(code)?.name
    },

    clearNetworkFilter() {
      this.networkFilter = ''
    },

    deleteApplication() {

      this.$refs.confirm.open(
        'Delete Application',
        'Deletion is permanent. Are you sure?',
      )

      .then(confirmed => {
        if (!confirmed) throw 0;

        this.deleting = true

        return this.removeApplication(this.application.id)
      })

      .then(() => {
        return this.$router.push({name: 'applications'})
      })

      .catch(error => {
        if (!error) return

        this.error = error?.response?.data ?? error?.message

        console.error(this.error)
      })

      .finally(() => {
        this.deleting = false
      })
    },
  },

  watch: {

    '$route.params.application_id': {
      handler(newValue) {
        this.applicationId = newValue
        this.application = this.applicationById(newValue) ?? {}
      },
      immediate: true,
    },

    applications: {
      handler() {
        this.application = this.applicationById(this.applicationId) ?? {}
      },
    },
  },
}
</script>

<style lang="scss" scoped>
/*::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
*/
.captioned {
    position: relative;
    display: inline-block;
    border: 1px dashed silver;
    padding: 4px;
    padding-right: 8px;
    padding-left: 8px;
    margin-right: 10px;
    border-radius: 6px;
    z-index: 1;
}

.captioned::after {
    content: attr(caption);
    position: absolute;
    bottom: -25px;
    right: 5px;
    /* background-color: white; */
    color: grey;
    font-size: 12px;
    z-index: 0;
}

</style>


